<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">审核管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">课件审核</a>
        </span>
      </div>
      <div class="framePage-body">
        <el-tabs v-model="activeName" :before-leave="beforeTabLeave" @tab-click="tabsChange">
          <el-tab-pane label="待审核" name="first">
            <CoursewareToBereviewed ref="first" />
          </el-tab-pane>
          <el-tab-pane label="已通过" name="second">
            <Coursewarepassed ref="second" />
          </el-tab-pane>
          <el-tab-pane label="已驳回" name="three">
            <CoursewareRejected ref="three" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import List from "@/mixins/List";
import CoursewareToBereviewed from "@/views/AuditManagement/CoursewareToBereviewed.vue";
import Coursewarepassed from "@/views/AuditManagement/Coursewarepassed.vue";
import CoursewareRejected from "@/views/AuditManagement/CoursewareRejected.vue";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "CoursewareReview",
  components: {
    CoursewareToBereviewed,
    Coursewarepassed,
    CoursewareRejected
  },
  mixins: [List],
  data() {
    return {
      activeName: "first"
    };
  },
  created() {},
  mounted() {
    this.activeName = this.$route.query.activeName || "first";
  },
  methods: {
    beforeTabLeave(newName) {
      this.$router.replace({
        path: this.$route.path,
        query: { activeName: newName }
      });
    },
    tabsChange(e) {
      this.$refs[e.name].ruleForm.Trainingtype = "";
      this.$refs[e.name].params = {};
      this.$refs[e.name].kpointName = "";
      this.$refs[e.name].kpointVideoId = "";
      this.$refs[e.name].getData(-1);
    }
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: function(route) {
      if (route.query.refresh) {
        this.$refs[route.query.activeName].getData(-1);
      }
    }
  }
};
</script>
<style lang="less">
.qrcode {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 400px;
    height: 400px;
    background-color: #999; //设置白色背景色
  }
}
.searchBox {
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
</style>
