<template>
  <div>
    <div class="operationControl">
      <div class="searchbox">
        <div title="课件ID" class="searchboxItem">
          <span class="itemLabel">课件ID:</span>
          <el-input
            v-model="kpointVideoId"
            type="text"
            size="small"
            placeholder="请输入课件ID"
            clearable
          />
        </div>
        <div title="课件名称" class="searchboxItem">
          <span class="itemLabel">课件名称:</span>
          <el-input v-model="kpointName" type="text" size="small" placeholder="请输入课件名称" clearable />
        </div>
        <span title="培训类型" class="searchboxItem ci-full flexcc">
          <span class="itemLabel" style="width:95px;">培训类型:</span>
          <tree
            ref="tree"
            @eventBtn="childBack"
            :ruleForm="ruleForm"
            size="small"
            @clearParams="clearParams"
            modal
            typeStu
          />
        </span>
        <div class="df">
          <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
        </div>
      </div>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="tableHeader"
          stripe
        >
          <el-table-column label="序号" align="center" type="index" :index="indexMethod" fixed min-width="100px"/>
          <el-table-column
            label="课件名称"
            align="left"
            show-overflow-tooltip
            prop="kpointName"
            min-width="250px"
            fixed
          />
          <el-table-column
            label="课件ID"
            align="left"
            show-overflow-tooltip
            prop="kpointVideoId"
            min-width="250px"
          />
          <el-table-column
            label="培训类型"
            align="left"
            show-overflow-tooltip
            prop="trainTypeNamePath"
            min-width="150px"
          />
          <el-table-column label="行业类型" align="left" :show-overflow-tooltip="true" min-width="100px">
            <template slot-scope="scope">{{ scope.row.industryNamePath || '--'}}</template>
          </el-table-column>
          <el-table-column label="岗位类型" align="left" :show-overflow-tooltip="true" min-width="70px">
            <template slot-scope="scope">{{ scope.row.postName || '--'}}</template>
          </el-table-column>
          <el-table-column label="职业/工种" align="left" :show-overflow-tooltip="true" min-width="120">
            <template slot-scope="scope">{{ scope.row.occupationNamePath || '--'}}</template>
          </el-table-column>
          <el-table-column label="培训等级" align="left" :show-overflow-tooltip="true" min-width="150px">
            <template slot-scope="scope">{{ scope.row.trainLevelName || '--'}}</template>
          </el-table-column>
          <el-table-column
            label="提交企业"
            align="left"
            show-overflow-tooltip
            prop="compName"
            min-width="200px"
          />
          <el-table-column
            label="上传时间"
            align="left"
            show-overflow-tooltip
            prop="createTime"
            min-width="150px"
          >
            <template slot-scope="scope">{{scope.row.createTime | moment}}</template>
          </el-table-column>
          <el-table-column
            label="审核人"
            align="left"
            show-overflow-tooltip
            prop="auditor"
            min-width="70px"
          >
            <template slot-scope="scope">
              <span>{{scope.row.auditor || '无'}}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="审核时间"
            align="left"
            show-overflow-tooltip
            prop="auditTime"
            min-width="150px"
          >
            <template slot-scope="scope">{{scope.row.auditTime | moment}}</template>
          </el-table-column>
          <el-table-column label="操作" align="center" fixed="right" width="80">
            <div class="flexcc" slot-scope="scope">
              <el-button
                v-if="!scope.row.isUsed"
                type="text"
                style="padding:0px 5px"
                size="mini"
                @click="doReview(scope.row.kpointId,scope.row.isUsed)"
              >审核</el-button>
              <el-button
                v-else
                type="text"
                style="padding:0px 5px"
                size="mini"
                @click="doReview(scope.row.kpointId,scope.row.isUsed)"
              >详情</el-button>
            </div>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import tree from "@/components/treePopup";
import { mapGetters } from "vuex";
export default {
  name: "CourseToBeReviewed",
  components: {
    Empty,
    PageNum,
    tree
  },
  mixins: [List],
  data() {
    return {
      kpointName: "",
      kpointVideoId: "",
      params: {},
      ruleForm: {
        Trainingtype: ""
      }
    };
  },
  computed: {},
  methods: {
    init() {
      this.getTableHeight();
      this.getData(-1);
    },
    /* tree */
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    getData(pageNum) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      if (this.kpointName) {
        params.kpointName = this.kpointName;
      }
      if (this.kpointVideoId) {
        params.kpointVideoId = this.kpointVideoId;
      }
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      this.doFetch({
        url: "/run/courseware/auditPassListPage",
        params,
        pageNum
      });
    },
    doReview(kpointId, isUsed) {
      this.$router.push({
        path: "/web/CoursewareReviewDetails",
        query: {
          kpointId,
          activeName: "second",
          stu: isUsed ? "look" : "edit"
        }
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 40 + 15;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    }
  },
  // watch: {
  //   $route: {
  //     handler: function(val, oldVal) {
  //       if (oldVal.meta.level == 2) {
  //         this.getData(-1);
  //       }
  //     },
  //     // 深度观察监听
  //     deep: true
  //   }
  // }
};
</script>
<style lang="less">
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;
    &:before {
      content: "\e6df" !important;
    }
  }
  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-tabs__content {
      flex: 1;
    }
  }
}
.btnBox {
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
</style>
